import React from "react"
import Header from '../layouts/header'
import Canvas from '../layouts/canvas'
import StartPage from '../layouts/startpagecontent'
import Footer from '../layouts/footer'
import Breadcrumb from '../layouts/breadcrumb'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'










class IndexPage extends React.Component {

   // constructor(props) {
   //   super(props)
   //   this.state={
   //     showpreloader:true
   //   }
   // }

  componentDidMount() {
  // this.setState({showpreloader:false})



    // const script = document.createElement("script");
    //
    //  script.src = "/main.js";
    //  script.async = false;
    //  script.id='mainscript';
    //  script.type = 'text/javascript';
    //  document.body.appendChild(script);


  }





  componentWillUnmount() {
    console.log('IndexPage Unmounting')
    // document.getElementById("mainscript").remove();
  }
  render() {


    return (

      <Canvas>

        <Helmet>
         {this.props.data.drupal.metainfo.entities[0].entityMetatags.map((item,i) => {
           if(item.key === 'title') {
             return (
                [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
             )
           }
   

           else if(item.key === 'description') {
             return (
              [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
             )
           }

           else {
             return null;
           }
         })}
         <link  rel="canonical" href={`${process.env.FRONT_URL}/el/`} />
         <meta property="og:type" content="Website" />
         <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
         <meta property="og:image" content={`${process.env.FRONT_URL}/wd_logo.png`} />
         <meta property="og:image:width" content="200" />
         <meta property="og:image:height" content="200" />
        </Helmet>


          <Header langpath={[{url:'/en', id:'en'}, {url:'/el', id:'el'}]} menu={this.props.data.drupal.menuel} language='EL' ishome={true} pathname="/en"/>


          <StartPage language='EL' data={this.props.data}/>
          <Footer/>

      </Canvas>



    )
  }

}





export default IndexPage

export const query = graphql`
{
  drupal {
nodeslider: nodeQuery(filter: {conditions: [{field: "type", value: "slider", language: EL},{field: "status", value: "1"}]}) {
 entities(language: EL) {
entityId
... on DRUPAL_NodeSlider {
 title
 body {
      processed
    }
 fieldButton {
      title
      url {
        path

      }
    }
 fieldBackground {
   entity {
     ... on DRUPAL_MediaRemoteVideo {
       fieldMediaOembedVideo
     }
     thumbnail {
                url
                alt
                imageFile {
                  publicURL
                }
              }
   }
 }
}
}
}
nodeintro: nodeById(id: "13", language:EL) {
title
... on DRUPAL_NodeIntro {
body {
  processed
}
fieldButton {
  title
  url {
    path
  }
}
}
}
nodeintro2: nodeById(id: "18", language: EL) {
title
... on DRUPAL_NodeIntro {
body {
processed
}
fieldButton {
title
url {
 path
}
}
}
}
nodeserv: nodeQuery(filter: {conditions: [{field: "type", value: "service", language: EL},{field: "status", value: "1"}]}) {
entities(language: EL) {
  ... on DRUPAL_NodeService {
    title
    body {
      summary
    }
    entityUrl {
     path
   }
    fieldImageIcon {
     url
     alt
     imageFile{
       publicURL
     }
   }
  }
}
}
nodesteps: nodeQuery(filter: {conditions: [{field: "type", value: "steps", language: EL},{field: "status", value: "1"}]}) {
entities(language: EL) {
  ... on DRUPAL_NodeSteps {
    title
    body{
      processed
    }
    fieldImage {
      url
      alt
      imageFile{
        publicURL
      }
    }
  }
}
}
nodeinfohome: nodeQuery(filter: {conditions: [{field: "type", value: "info_home", language: EL},{field: "status", value: "1"}]}) {
entities(language:EL) {

 ... on DRUPAL_NodeInfoHome {
   title
   body {
     processed
   }
   fieldIcon
 }
}
}
metainfo:nodeQuery(filter: {conditions: [{field: "title", value: "home"}]}) {
     entities(language: EL) {
       ... on DRUPAL_NodePage {
         entityLabel
         entityMetatags {
           key
           value
         }
       }
     }
   }
menuel: menuByName(name: "main", language: EL) {
  links {
    label
    url {
      path
    }
  }
}
nodeclients: nodeQuery(filter: {conditions: [{field: "type", value: "clients"},{field: "status", value: "1"}]}, limit:50) {
entities {
  ... on DRUPAL_NodeClients {

         fieldImage {
           url
           alt
           imageFile {
             publicURL
           }
         }
       }
}
}
}
}
`
